document.addEventListener('turbolinks:load', function() {
  const flash = document.querySelector('[data-flash]');
  if (flash) {
    flash.removeAttribute('aria-hidden');

    setTimeout(() => {
      flash.setAttribute('aria-hidden', true);
    }, 5000);
  }
});
