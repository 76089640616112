document.addEventListener('turbolinks:load', function() {
  const fileInputs = document.querySelectorAll('[data-file-input]');

  const updateSelectedFileMessage = function() {
    let messageField = this.parentElement.querySelector('[data-file-input-message]');
    if (messageField) {
      messageField.value = this.files[0].name; // this.file.replace(/C:\\fakepath\\/i, '');
    }
  }

  fileInputs.forEach((fileInput) => {
    fileInput.addEventListener('change', updateSelectedFileMessage);

    fileInput.nextSibling.addEventListener('click', (event) => {
      event.preventDefault();

      fileInput.parentElement.previousSibling.click();
    });
  });
});
