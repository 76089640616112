document.addEventListener('turbolinks:load', function() {
  /*
   * Toggle for PageSidebar
   */
  const sidebarToggle = document.querySelector('[data-page-sidebar-toggle]');
  const page = document.querySelector('[data-page]');

  function isSidebarOpen() {
    return window.getComputedStyle(page).getPropertyValue('margin-left') === '0px';
  }

  if (sidebarToggle) {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    if (vw < 1024) {
      page.classList.remove('sidebar-open');
      sidebarToggle.classList.remove('is-open');
      page.classList.add('sidebar-closed');
      sidebarToggle.classList.add('is-closed');
    }

    sidebarToggle.addEventListener('click', (event) => {
      event.preventDefault();

      if (isSidebarOpen()) {
        page.classList.remove('sidebar-open');
        sidebarToggle.classList.remove('is-open');
        page.classList.add('sidebar-closed');
        sidebarToggle.classList.add('is-closed');
      } else {
        page.classList.add('sidebar-open');
        sidebarToggle.classList.add('is-open');
        page.classList.remove('sidebar-closed');
        sidebarToggle.classList.remove('is-closed');
      }
    });
  }
});
