
document.addEventListener('turbolinks:load', function() {
  /*
   * Toggle for nested VerticalNavigation Lists
   */
  const parentLinks = document.querySelectorAll('.vertical-navigation--item.has-submenu > .vertical-navigation--link');

  if (parentLinks) {
    parentLinks.forEach((parentLink) => {
      parentLink.addEventListener('click', (event) => {
        const listParent = parentLink.parentElement;
        event.preventDefault();

        listParent.classList.toggle('is-open');
        listParent.querySelector('.vertical-navigation--list').classList.toggle('is-closed');
      });
    });
  }
});
