document.addEventListener('turbolinks:load', function() {
  const passwordField = document.querySelector('[data=new-password]');
  const passwordConfirmationField = document.querySelector('[data=password-confirmation]');

  if (passwordField) {
    const passwordLength = document.querySelector('[data-password-hint=length]');
    const passwordUppercase = document.querySelector('[data-password-hint=uppercase]');
    const passwordLowercase = document.querySelector('[data-password-hint=lowercase]');
    const passwordNumber = document.querySelector('[data-password-hint=number]');

    const togglePasswordButton = document.querySelector('[data-toggle-password]');
    if (togglePasswordButton) {
      togglePasswordButton.addEventListener('click', function(e) {
        e.preventDefault();

        if (passwordField.type === 'password') {
          passwordField.type = 'text';
          passwordConfirmationField.type = 'text';
          togglePasswordButton.innerHTML = '<span class="button--icon"><svg class="icon icon-hide-password " aria-hidden="true"><use xlink:href="#icon-hide-password"></use></svg></span>Hide';
        } else {
          passwordField.type = 'password';
          passwordConfirmationField.type = 'password';
          togglePasswordButton.innerHTML = '<span class="button--icon"><svg class="icon icon-show-password " aria-hidden="true"><use xlink:href="#icon-show-password"></use></svg></span>Show';
        }
      });
    }

    passwordField.addEventListener('keyup', function() {
      if (passwordField.value.length >= 12) {
        passwordLength.classList.add('checked');
      } else {
        passwordLength.classList.remove('checked');
      }

      if (/[A-Z]/.test(passwordField.value)) {
        passwordUppercase.classList.add('checked');
      } else {
        passwordUppercase.classList.remove('checked');
      }

      if (/[a-z]/.test(passwordField.value)) {
        passwordLowercase.classList.add('checked');
      } else {
        passwordLowercase.classList.remove('checked');
      }

      if (/\d/.test(passwordField.value)) {
        passwordNumber.classList.add('checked');
      } else {
        passwordNumber.classList.remove('checked');
      }
    });
  }
});
