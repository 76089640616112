import Choices from "choices.js";

document.addEventListener('turbolinks:load', function() {
  const bankExclusionsField = document.querySelector('[data=bank-exclusion-multiselect]');
  if (bankExclusionsField) {
    const choices = new Choices(bankExclusionsField, {
      removeItemButton: true
    });
  }
});
