document.addEventListener('turbolinks:load', function() {
  var i, len, link, currencyFields, formatCurrency;

  formatCurrency = function() {
    var value = this.value.replace(/\D/g,''),
        originalField = this.previousSibling,
        parsedValue;

    if (value !== '') {
      parsedValue = Number.parseInt(value);
      if (!isNaN(parsedValue)) {
        originalField.value = parsedValue;
      }
      this.value = Number.parseInt(originalField.value).toLocaleString('en-UK')
    } else {
      this.value = '';
      originalField.value = '';
    }
  };

  currencyFields = document.querySelectorAll('[data=add-thousands-delimiter]');

  for (i = 0, len = currencyFields.length; i < len; i++) {
    var originalField,
        formatedField,
        event = document.createEvent('HTMLEvents');

    originalField = currencyFields[i];
    formatedField = originalField.cloneNode(true);
    originalField.removeAttribute('data');

    formatedField.removeAttribute('name');
    formatedField.removeAttribute('id');
    formatedField.type = 'text';

    originalField.parentNode.insertBefore(formatedField, originalField.nextSibling);
    originalField.type = 'hidden';
    formatedField.addEventListener('keyup', formatCurrency);
    event.initEvent('keyup', false, true);
    formatedField.dispatchEvent(event);
  }
});
