document.addEventListener('turbolinks:load', function() {
  /*
   * Toggle for DropdownMenu
   */
  const dropdownToggles = document.querySelectorAll('[data-dropdown-menu-toggle]');

  // Open / close dropdown when clicking on the toggle
  dropdownToggles.forEach((toggle) => {
    toggle.addEventListener('click', (event) => {
      event.preventDefault();

      const toggleElement = event.currentTarget;
      if (!(toggleElement instanceof Element)) return;

      const isExpanded = toggleElement.getAttribute('aria-expanded') === 'true';

      toggleElement.setAttribute('aria-expanded', (!isExpanded).toString());
    });
  });

  // Close dropdowns when clicking outside of them
  document.addEventListener('click', (event) => {
    dropdownToggles.forEach((toggle) => {
      if (!(event.target instanceof Node)) return;
      if (toggle.closest('[data-dropdown-menu]').contains(event.target)) return;

      toggle.setAttribute('aria-expanded', 'false');
    });
  });
});
